
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <form @submit.prevent="updateWorkingHours" autocomplete="off">
                <v-layout row wrap>
        
                   <v-flex xs12 lg4 xl4 md6 sm6>
                        <v-text-field v-model="working_hours.working_hour_start" type="time" :label="$store.getters.language.data.working_hours.working_hour_start" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                   <v-flex xs12 lg4 xl4 md6 sm6>
                        <v-text-field v-model="working_hours.working_hour_end" type="time" :label="$store.getters.language.data.working_hours.working_hour_end" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                       <v-flex xs12 lg4 xl4 md6 sm6>
                        <v-text-field v-model="working_hours.working_hour_per_day" type="text" :label="$store.getters.language.data.working_hours.working_hour_per_day" dense class="mx-1" filled outlined required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.working_hours.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/working_hours.request.js'
    export default {
        data() {
            return {
                working_hours: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneWorkingHours()
        },
        methods: {
            getOneWorkingHours() {
                this.loading = true
                requests.getOneWorkingHours(this.id).then(r =>{
                    if (r.status == 200) {
                        this.working_hours = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read WorkingHours',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read WorkingHours',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateWorkingHours() {
                // if working_hours.working_hour_per_day is not a number then return
                if (isNaN(this.working_hours.working_hour_per_day)) {
                    this.snackbar = {
                        value: true,
                        text: 'Working Hours Per Day must be a number',
                        color: 'error'
                    }
                    return
                }
                this.loading = true
                requests.updateWorkingHours(this.id,this.working_hours).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'WorkingHours Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    